(function ($) {
  var $body = $('body');
  var $header = $('.site-header');
  var $headerStickyClass = 'is-sticky';
  var $winScrTop = $(window).scrollTop();
  
  function headerSticky() {
    if ($winScrTop > 20) {
      $header.addClass($headerStickyClass);
    } else {
      $header.removeClass($headerStickyClass);
    }
  }

  function reEmailValid($email) {
	  var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	  return re.test($email);
	}

  function inputValid($input) {
    var $error = false;
    var $msg = '';
    var $group = $input.parents('.form__group');
    var $form_msg = $group.find('.form__msg');

    if ($input.val() === '') {
      $error = true;
      $msg = $form_msg.data('empty');
    }
    else if ($input.attr('type') === 'email' && !reEmailValid($input.val())) {
      $error = true;
      $msg = $form_msg.data('feedback');
    }
    else if ($input.attr('id') === 'pwd' && $input.val().length < 8) {
      $error = true;
      $msg = $form_msg.data('feedback');
    }
    else if ($input.attr('id') === 'vpwd' && $input.val() !== $('#pwd').val()) {
      $error = true;
      $msg = $form_msg.data('feedback');
    }

    if ($error) {
      $group.addClass('has-error');
      $form_msg.html($msg);
    } else {
      $group.removeClass('has-error');
      $form_msg.html('');
    }

    return $error;
  }

  function updateMeter($strength) {
    var $ratings = ["Weak", "Pretty weak", "Medium", "Strong", "Very strong"];
    $('.pwp').removeClass('active');
    $('#pwr').html('');

    for (var i = 1; i <= $strength; i++) {
      $('#pwm-' + i).addClass('active');
    }
    $('#pwr').html($ratings[$strength - 1]);
  }

  function passwordMeter($password) {
    var $regex = [/[a-z]+/, /[A-Z]+/, /[0-9]+/, /.[!,@,#,$,%,^,&,*,?,_,~,-,(,)]/];
    var $strength = 0;

    updateMeter($strength);

    if ($password.length >= 8) {
      $strength++;
      updateMeter($strength);
    }

    $.each($regex, function (i, pattern) {
      if ($password.match(pattern)) {
        $strength++;
        
        updateMeter($strength);
      }
    });
  }

  headerSticky();

  $(document).on('click', function (e) {
    if (!$(e.target).hasClass('side-menu') && !$(e.target).hasClass('js-side-menu') && !$(e.target).closest('.side-menu').length && !$(e.target).closest('.js-side-menu').length) {
      $body.removeClass('side-menu-opened');
    }
  });

  $('.menu-aside > li.menu-item-has-children').click(function (e) {
    if(e.target.tagName !== 'A') {
      e.preventDefault();
      $(this).toggleClass('opened');
    }
  });

  $('.js-side-menu').click(function (e) {
    e.preventDefault();
    $body.toggleClass('side-menu-opened');
  });

  $('form .js-req').keyup(function (e) { 
    var $this = $(this);

    inputValid($this);
  });

  $('#pwd').keyup(function (e) { 
    passwordMeter($(this).val());
  });

  $('form').submit(function (e) {
    var $success = true;
    var $fields = $(this).find('.js-req');

    $fields.each(function (i, input) {
      if (inputValid($(input))) {
        $success = false;
      }
    });

    if (!$success) {
      e.preventDefault();
    }
  });

  $('.js-compare-btn').click(function (e) { 
    e.preventDefault();
    if (!$(this).hasClass('active')) {
      $('.js-compare-btn.active').removeClass('active');
      $(this).addClass('active');
      var $target = $(this).data('target');

      $('.js-compare:not(.d-none)').addClass('d-none');
      $($target).removeClass('d-none');
    }

    $(this).parent().toggleClass('opened');
  });

  $(window).scroll(function () {
    $winScrTop = $(this).scrollTop();
    headerSticky();
  });

  $('.js-search').click(function () {
    if (!$header.hasClass('search-opened')) {
      setTimeout(function () { $('#searchfield').focus(); }, 100);
      $header.addClass('search-opened');
    } else {
      $header.removeClass('search-opened');
    }
  });

  $(document).on('click', function (e) {
    if (!$(e.target).hasClass('js-search') && !$(e.target).closest('.js-search').length && !$(e.target).closest('.searchform').length) {
      $header.removeClass('search-opened');
    }
  });
})(jQuery);